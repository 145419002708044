import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useSurveyList() {

  const surveyListTable = ref([])

  const tableColumns = [
    { text: 'CÓDIGO', value: 'code' },
    { text: 'DESCRIPCIÓN', value: 'description' },
    { text: 'ESTRUCTURA', value: 'structure', sortable: false, align: 'center' },
    { text: 'ESTADO', value: 'stated_at', sortable: false },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const filterLists = ref([])
  filterLists.value =  [{
    value: 'stated_at',
    list: [{text: 'Activo', value: true}, {text: 'Inactivo', value: false}]
  }]


  const filterFields = [
    { text: 'CÓDIGO', value: 'code', type: 'text' },
    { text: 'DESCRIPCIÓN', value: 'description', type: 'text'},
    { text: 'ESTADO', value: 'stated_at', type: 'lookup' },
  ]

  const roleOptions = [
    { text: 'Administrador', value: 'admin' },
    { text: 'Gestor', value: 'project_manager' },
    { text: 'Comité Evaluador', value: 'commitee' },
    { text: 'Auditor', value: 'auditor' },
    { text: 'Patrocinador', value: 'sponsor' },
    { text: 'Miembro', value: 'team_member' },
  ]

  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })

  // fetch data
  const fetchSurveys = () => {
    loading.value = true
    store
      .dispatch('surveys/fetchSurveys')
      .then(response => {

        const data = ref([])
        if (response) data.value = JSON.parse(response[0].listsurveys)['surveys']
        surveyListTable.value = data.value
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }


  watch([], () => {
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*



  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    else return 'error'
  }


  return {
    fetchSurveys,
    filterFields,
    filterLists,
    surveyListTable,
    tableColumns,
    loading,
    options,
    resolveUserStatusVariant,
    roleOptions
  }
}
