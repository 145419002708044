var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[(_vm.showSurvey)?_c('dialog-show-survey',{model:{value:(_vm.showSurvey),callback:function ($$v) {_vm.showSurvey=$$v},expression:"showSurvey"}}):_vm._e(),_c('v-card-title',{staticClass:"pb-2"},[_vm._v(" Encuestas "),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('router-link',{attrs:{"to":{ name: 'survey-creator'}}},[_c('v-btn',{staticClass:"mb-1 me-3",attrs:{"color":"primary"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nuevo")])],1)],1),_c('v-btn',{staticClass:"mb-1",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Exportar")])],1)],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0 pl-0 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('filter-by',{attrs:{"filterFields":_vm.filterFields,"filterLists":_vm.filterLists},model:{value:(_vm.newItems),callback:function ($$v) {_vm.newItems=$$v},expression:"newItems"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.newItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.stated_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.stated_at)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.stated_at)}},[_vm._v(" "+_vm._s(item.stated_at ? 'Activo' : 'Inactivo')+" ")])]}},{key:"item.structure",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.show(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Vista Previa")])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#4CAF50"},on:{"click":function($event){return _vm.setSurvey(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Inactivar")])])],1)]}}],null,true)})],1),(_vm.downloadPdf)?_c('report',{attrs:{"download":_vm.downloadPdf = false}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }