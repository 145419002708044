<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <dialog-show-survey v-model="showSurvey" v-if="showSurvey"></dialog-show-survey>
      <v-card-title class="pb-2">
        Encuestas
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <router-link
            :to="{ name: 'survey-creator'}"
          >
            <v-btn
              color="primary"
              class="mb-1 me-3"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Nuevo</span>
            </v-btn>
          </router-link>

          <v-btn
            color="secondary"
            outlined
            class="mb-1"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider class="mt-2"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0 pl-0 pt-0">
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="newItems"
        :options.sync="options"
        :loading="loading"
      >

        <template #[`item.stated_at`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.stated_at)"
            :class="`${resolveUserStatusVariant(item.stated_at)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.stated_at ? 'Activo' : 'Inactivo'}}
          </v-chip>
        </template>

        <template #[`item.structure`]="{item}">
          <div class="d-flex align-center justify-center">
          <!-- delete -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="show(item)"
                >
                  <v-icon size="18">
                    {{ icons.mdiFileEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Vista Previa</span>
            </v-tooltip>

          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
          <!-- delete -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="setSurvey(item)"
                  color="#4CAF50"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>


            <!-- view  -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Inactivar</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

    </v-card>
    <report
      v-if="downloadPdf"
      :download="downloadPdf = false"
    ></report>
  </div>
</template>

<script>
import { mdiPlus, mdiFileEyeOutline, mdiFilePdfBox, mdiPencilOutline, mdiCancel, mdiExportVariant } from '@mdi/js'
import { useRouter } from '@core/utils'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import DialogShowSurvey from '@/views/survey/survey-show/DialogShow.vue'
import { ref, watch, provide } from '@vue/composition-api'
import useSurveyList from './useSurveyList'
import Report from './Report'

export default {
  components: { DialogShowSurvey, FilterBy, Report },
  setup() {
    const { router } = useRouter()
    const newItems = ref([])
    const survey = ref({})
    const downloadPdf = ref(false)
    const showSurvey = ref(false)
    provide('showSurvey', showSurvey)
    provide('survey', survey)
    provide('fullscreen', false)
    const {
      surveyListTable,
      tableColumns,
      loading,
      options,

      fetchSurveys,
      filterFields,
      filterLists,
      resolveUserStatusVariant,
    } = useSurveyList()

    fetchSurveys()

    const show = item => {
      survey.value = JSON.parse(item.structure_survey)
      showSurvey.value = true
    }

    const setSurvey = item => {
      router.push({ name: 'survey-creator-id', params: { id: item.id } })
    }

    watch(
      surveyListTable,
      () => {
        newItems.value = surveyListTable.value
      },
      {
        deep: true,
      },
    )

    return {
      downloadPdf,
      filterFields,
      filterLists,
      loading,
      newItems,
      options,
      resolveUserStatusVariant,
      setSurvey,
      show,
      showSurvey,
      tableColumns,
      icons: {
        mdiCancel,
        mdiExportVariant,
        mdiFilePdfBox,
        mdiFileEyeOutline,
        mdiPencilOutline,
        mdiPlus,
      },
    }
  },
}
</script>
